<template>
  <div class="head_top">
    <img src="../../../public/images/头部导航二级页面/APP/智驹-APP介绍-中文_2_01.jpg" lazy width="100%" height="100%">
    <img src="../../../public/images/头部导航二级页面/APP/智驹-APP介绍-中文_2_02.jpg" lazy width="100%" height="100%">
    <img src="../../../public/images/头部导航二级页面/APP/智驹-APP介绍-中文_2_03.jpg" lazy width="100%" height="100%">
    <img src="../../../public/images/头部导航二级页面/APP/智驹-APP介绍-中文_2_04.jpg" lazy width="100%" height="100%">
    <img src="../../../public/images/头部导航二级页面/APP/智驹-APP介绍-中文_2_05.jpg" lazy width="100%" height="100%">
    <img src="../../../public/images/头部导航二级页面/APP/智驹-APP介绍-中文_2_06.jpg" lazy width="100%" height="100%">
    <img src="../../../public/images/头部导航二级页面/APP/智驹-APP介绍-中文_2_07.jpg" lazy width="100%" height="100%">
    <img src="../../../public/images/头部导航二级页面/APP/智驹-APP介绍-中文_2_08.jpg" lazy width="100%" height="100%">
    <img src="../../../public/images/头部导航二级页面/APP/智驹-APP介绍-中文_2_09.jpg" lazy width="100%" height="100%">
    <img src="../../../public/images/头部导航二级页面/APP/智驹-APP介绍-中文_2_10.jpg" lazy width="100%" height="100%">
  </div>
</template>

<script>
export default {
  name: "APP"
}
</script>

<style scoped>

</style>